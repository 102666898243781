<template>
  <div class="mt--8 pb-5 container">
    <div class="card bg-secondary border-0">
      <b-row class="rule-create">
        <b-col lg="10" class="mx-auto">
          <h1 class="text-center text-center">Add a New Rule</h1>
          <Spinner v-if="isLoading"></Spinner>
          <b-row class="border-bottom sticky-top bg-white page-header">
            <div class="d-flex align-items-center">
              <b-link @click="$router.go(-1)">
                <font-awesome-icon icon="arrow-left-long" />
              </b-link>
              <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">Back</h5>
              <b-button @click.prevent="saveSettings" :disabled="isLoading">Save</b-button>
            </div>
          </b-row>
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  id="input-group-2"
                  label="Details"
                  label-for="details"
                >
                  <b-form-textarea
                    id="details"
                    v-model="details"
                    placeholder="Enter details"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Status" label-for="status" class="mb-3">
                  <b-form-select
                    id="status"
                    v-model="status"
                    required
                    :options="options"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="input-group-4"
                  label="Destination Prefix"
                  label-for="destinationPrefix"
                >
                  <b-form-input
                    id="destinationPrefix"
                    name="destinationPrefix"
                    v-model="destinationPrefix"
                    type="text"
                    placeholder="e.g: '100.10.0.0/16'"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  id="input-group-4"
                  label="RateLimit"
                  label-for="rate_limit"
                >
                  <b-form-input
                    id="rate_limit"
                    name="rate_limit"
                    v-model="rate_limit"
                    type="text"
                    placeholder="Enter rate limit"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-7"
                  label="Source Port"
                  label-for="sourcePort"
                >
                  <b-form-input
                    id="sourcePort"
                    name="sourcePort"
                    v-model="sourcePort"
                    min="0"
                    type="number"
                    placeholder="Enter sourcePort"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="input-group-5"
                  label="Source Prefix"
                  label-for="sourcePrefix"
                >
                  <b-form-input
                    id="sourcePrefix"
                    name="sourcePrefix"
                    v-model="sourcePrefix"
                    type="text"
                    placeholder="e.g: '100.10.0.0/16'"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  id="input-group-6"
                  label="IP Protocol"
                  label-for="ipProtocol"
                >
                  <b-form-select
                    id="ipProtocol"
                    v-model="ipProtocol"
                    :options="ipProtocolOptions"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-10"
                  label="Specific Protocols(optional)"
                  label-for="extraProtocols"
                >
                  <b-form-input
                    id="extraProtocols"
                    name="extraProtocols"
                    v-model="extraProtocols"
                    :disabled="ipProtocol != 'Unknown'"
                    type="number"
                    placeholder="e.g 9"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-10"
                  label="ICMP Code"
                  label-for="ICMPCode"
                >
                  <b-form-input
                    id="ICMPCode"
                    name="ICMPCode"
                    v-model="ICMPCode"
                    type="number"
                    placeholder="Enter ICMPCode"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-12"
                  label="Packet Length"
                  label-for="packetLength"
                >
                  <b-form-input
                    id="packetLength"
                    name="packetLength"
                    v-model="packetLength"
                    type="number"
                    placeholder="Enter packetLength"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group
                  id="input-group-8"
                  label="Destination Port"
                  label-for="destinationPort"
                >
                  <b-form-input
                    id="destinationPort"
                    name="destinationPort"
                    v-model="destinationPort"
                    type="number"
                    min="0"
                    placeholder="Enter destination port"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="IS blocked" label-for="block" class="mb-3">
                  <b-form-select
                    id="block"
                    v-model="is_blocked"
                    :options="booleanOptions"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="input-group-9"
                  label="ICMP Type"
                  label-for="ICMPType"
                >
                  <b-form-input
                    id="ICMPType"
                    name="ICMPType"
                    v-model="ICMPType"
                    type="text"
                    placeholder="e.g: '1'"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  id="input-group-11"
                  label="TCP Flags"
                  label-for="TCPflags"
                >
                  <b-form-input
                    id="TCPflags"
                    name="TCPflags"
                    v-model="TCPflags"
                    type="text"
                    placeholder="e.g: FS, SA, R"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group id="input-group-13" label="DSCP" label-for="DSCP">
                  <b-form-input
                    id="DSCP"
                    name="DSCP"
                    v-model="DSCP"
                    type="text"
                    placeholder="e.g: 10"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  id="input-group-14"
                  label="FR Encoding"
                  label-for="FREncoding"
                >
                  <b-form-input
                    id="FREncoding"
                    name="FREncoding"
                    v-model="FREncoding"
                    type="text"
                    placeholder="Enter FREncoding"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import FlowSpecService from "../../services/flow-spec.service";
import { notify } from "../../helpers/index";
import "vue3-toastify/dist/index.css";
import Spinner from "../../components/spinner/spinner.vue";
export default {
  name: "FlowSpecCreate",
  components: {
    Spinner,
  },
  data: function () {
    return {
      rate_limit: 0,
      details: "",
      is_blocked: false,
      isLoading: false,
      status: "active",
      destinationPrefix: "",
      sourcePrefix: "",
      ipProtocol: "UDP",
      extraProtocols: "",
      sourcePort: null,
      destinationPort: null,
      ICMPType: "",
      ICMPCode: 0,
      TCPflags: "",
      packetLength: 0,
      DSCP: "",
      FREncoding: "",
      errors: {},
      options: [
        {
          value: "active",
          text: "Active",
        },
        {
          value: "inactive",
          text: "Inactive",
        },
      ],
      booleanOptions: [
        {
          value: true,
          text: "True",
        },
        {
          value: false,
          text: "False",
        },
      ],
      ipProtocolOptions: [
        {
          value: "UDP",
          text: "UDP",
        },
        {
          value: "TCP",
          text: "TCP",
        },
        
        {
          value: "Unknown",
          text: "Any",
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async saveSettings() {
      try{
        this.errors = {};
        this.isLoading = true;
        const response = await FlowSpecService.createFlowSpecRules(
          "flowspec/create",
          {
            rate_limit: this.rate_limit,
            details: this.details,
            status: this.status,
            is_blocked: this.is_blocked,
            destinationPrefix: this.destinationPrefix,
            sourcePrefix: this.sourcePrefix,
            ipProtocol: this.extraProtocols ? this.extraProtocols?.toString() : this.ipProtocol,
            sourcePort: this.sourcePort ? this.sourcePort * 1 : null,
            destinationPort: this.destinationPort ? this.destinationPort * 1 : null,
            ICMPType: this.ICMPType,
            ICMPCode: this.ICMPCode,
            TCPflags: this.TCPflags,
            packetLength: this.packetLength,
            DSCP: this.DSCP,
            FREncoding: this.FREncoding,
          }
        );
        if (response?.status == 200) {
          notify("Manual Rule added successfully");
          this.isLoading = false;
          this.$router.push({ name: "rule" });
        }
        else{
          notify("Manual Rule addition failed", "error");
          this.isLoading = false;
        }
      }
      catch{
          notify("Manual Rule addition failed", "error");
          this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.bg-secondary {
  background-color: #f7fafc !important;
}
</style>
